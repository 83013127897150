import React, { useEffect } from 'react';

import { Header as AppHeader } from '@bestelleck/shared';
import { captureException } from '@sentry/react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { setCurrentRoute, setPostalCode } from '../../redux/app/app.actions';
import { RootState } from '../../redux/store';
import { reverseGeoCode } from '../../services/geo.service';
import { isHunger } from '../../util/constants';
import { FEATURES } from '../../util/features';

import { PostalCodeComponent } from './PostalCode/PostalCode';

const Header: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const userLocation = useSelector((state: RootState) => state.app.location, shallowEqual);

    useEffect(() => {
        dispatch(setCurrentRoute(location.pathname));
    }, [location, dispatch]);

    useEffect(() => {
        const checkLocation = async () => {
            const currentPlace = userLocation.place;
            if (currentPlace) {
                try {
                    const geo = await reverseGeoCode({ latitude: currentPlace.lat, longitude: currentPlace.lon });
                    dispatch(
                        setPostalCode({
                            deliveryPlaces: geo,
                            place: currentPlace,
                        }),
                    );
                } catch (error) {
                    captureException(error);
                }
            }
        };
        checkLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isRootLocation = location.pathname === '/';
    const showPostalCode =
        !isRootLocation &&
        !location.pathname.includes('checkout') &&
        !location.pathname.includes('completed') &&
        location.pathname !== '/info' &&
        location.pathname !== '/impressum' &&
        location.pathname !== '/agb' &&
        location.pathname !== '/datenschutz';
    const history = useHistory();

    const onBack = () => {
        if (location.pathname === '/discover') {
            return history.push('/');
        }
        if (!isRootLocation && !location.pathname.includes('checkout')) {
            return history.push('/discover');
        }
        return history.goBack();
    };

    const menuEntries = [
        { link: '/', name: 'Home' },
        { link: '/discover', name: 'Entdecken' },
        { link: '/info', name: 'Für Restaurants' },
    ];

    return (
        <AppHeader entries={menuEntries} onBack={onBack} isHunger={isHunger}>
            {FEATURES.postalCodeHeader && showPostalCode && <PostalCodeComponent />}
        </AppHeader>
    );
};

export default Header;
